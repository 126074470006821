// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1280px;

// Extra extra large desktops
$screen-xxl-min: 1920px;

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) and (orientation : portrait) {
        @content;
    }
 }

 @mixin sm-landscape {
    @media (min-width: #{$screen-sm-min}) and (orientation : landscape) {
        @content;
    }
 }
 
 //  Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) and (orientation : portrait) {
        @content;
    }
 }

@mixin md-landscape {
    @media (min-width: #{$screen-md-min}) and (orientation : landscape) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }

  // Extra extra large devices
  @mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
 }
@import '../../../styles/breakpoints.scss';

.block {
    width: 100%;
    margin-bottom: 32px;

    @include lg {
        margin-bottom: 64px;
    }
}

.left {
    text-align: left;
}

.right {    
    @include lg {
        .content {
            flex-direction: row-reverse;
        }
    }
}

.center {
    text-align: center;
    align-self:  center;

    .content {
        flex-direction: column;
    }
}

.richTextWrapper {
    margin-bottom: 32px;
    @include lg {
        flex: 0.8;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;

    @include lg {
        flex-direction: row;
        gap: 6rem;
    }
}

.imgWrapper {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 60lvh;
        object-fit: cover;

        @include sm-landscape {
            height: 90lvh;
        }

        @include lg {
            height: 90lvh;
        }

        @include xl {
            height: 90lvh;
        }

        @include xxl {
            height: 60lvh;
        }
    }

    @include lg {
        flex: 1;
    }
}

.gallery {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 32px;

    img {
        width: 100%;
        height: 60lvh;
        object-fit: cover;

        @include xl {
            width: 49%;
            height: 90lvh;
        }

        @include xxl {
            width: 49%;
            height: 60lvh;
        }
    }

    .right {
        align-self: flex-end;
    }

    .center {
        align-self: center;
    }

    .left {
        align-self: flex-start;
    }
}
@import '../../styles/animations.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';

.about {
    width: 100%;
    overflow-x: hidden;
}

.line {
    height: 1px;
    width: 60%;
}

.slideInLeft {
    animation: slideInLeft 1s forwards;
}

.slideInRight {
    animation: slideInRight 1s forwards;
}

.slideOutLeft {
    animation: slideOutLeft 1s forwards;
}

.slideOutRight {
    animation: slideOutRight 1s forwards;
}

.fadeIn {
    animation: fadeIn 1s forwards;
}

.fadeOut {
    animation: fadeOut 1s forwards;
}

.wrapper {
    padding: 48px 0; 
}

.text {
    margin-bottom: 64px;
}


.topLine {
    @extend .line;
    margin-left: -$padding-sm; 

    @include md {
        margin-left: -$padding-md; 
    }
}

.bottomLine {
    @extend .line;
    float: right;
    margin-right: -$padding-sm; 

    @include md {
        margin-right: -$padding-md; 
    }
}

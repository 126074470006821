@import '../../styles/variables.scss';
@import '../../styles/animations.scss';

.landingPage {
    width: 100%;
    height: 100lvh;
    display: flex;
    transition: opacity 1s;
    padding-left: 3dvw;
    justify-content: center;
    align-items: center;
    margin-bottom: 10lvh;

   
}

.intro {
    margin-bottom: 10lvh;

    h1 {
        font-family: "Nebula";
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: 0.1rem;
        padding: 0;
        margin: 0;
       /*  font-variation-settings: "wdth" 150, "wght" 900;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        color: transparent; */
    }
}
@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';
@import '../../styles/animations.scss';

.footer {
    display: flex;
    bottom: 0;
    left: 0;
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
    padding-bottom: 16px;
    opacity: 0;
    animation: fadeIn 1s 2s forwards;

    @include xl {
        flex-direction: row;
        justify-content: flex-end;
        width: calc(100% + $padding-xl * 2);
        margin-left: -$padding-xl;
        padding: 16px;
        justify-content: space-between;
    }
}

.socialMedia {
    display: flex;
    gap: 1.4rem;
}

.tradeMark {
    opacity: 0.3;
}
@import '../../../styles/variables.scss';

.skill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    
    .mastery {
        position: relative;
        display: inline-block;
        width: 120px;
        height: 10px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;

        .percentage {
            position: absolute;
            display: inline-block;
            background-color: #16FFFF;
            height: 8px;
            border-radius: 2px;
            transition: width 0.6s ease-in-out;
        }
    }
}
@import '../../styles/breakpoints.scss';

.error {
    height: 100lvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20%;

    p {
        font-family: "Sixtyfour", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-variation-settings:
          "BLED" 0,
          "SCAN" 0;      
        font-size: 2rem;
        line-height: 2.3rem;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgb(255, 255, 255);
        color: transparent;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
        text-align: center;
        margin-bottom: 2rem;
    }
}
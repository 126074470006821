@import './variables.scss';
@import './breakpoints.scss';
@import './animations.scss';

@font-face {
  font-family: DuneRise;
  src: url("../../public/fonts/abnes.ttf") format("ttf"),
  url("../../public/fonts/abnes.otf") format("opentype");
}

@font-face {
  font-family: Nebula;
  src: url("../../public/fonts/Nebula-Regular.otf") format("opentype");
}

body:before {
	content: "xsmall";
	display: none;
	visibility: hidden;
}

// Small devices
@include sm {
  body:before {
		content: "small";
	}
}

@include sm-landscape {
  body:before {
		content: "small-landscape";
	}
}

// Medium devices
@include md {
  body:before {
		content: "medium";
	}
}

@include md-landscape {
  body:before {
		content: "medium-landscape";
	}
}

// Large devices
@include lg {
  body:before {
		content: "large";
	}
}

// Extra large devices
@include xl {
  body:before {
		content: "xlarge";
	}
}

// Extra large devices
@include xxl {
  body:before {
		content: "xxlarge";
	}
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  overscroll-behavior: contain;
}

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: 14px;
  font-family: "Lekton", monospace;
  letter-spacing: 0.025rem;
  font-weight: 400;
  font-style: normal;
  scrollbar-color: rgba(#16FFFF, 0.3) black;
  scrollbar-width: thin;

  @include sm {
    font-size: 16px;
  }

  @include lg {
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color, 0.3); /* Set thumb color for Chrome and Safari */
  }
  
  &::-webkit-scrollbar-track {
    background-color: black; /* Set track color for Chrome and Safari */
  }
}

main {
  width: 100%;
  padding: 0 $padding-sm;
  max-width: 1440px;

  @include md {
    padding: 0 $padding-md;
  }

  @include xl {
    padding: 0 $padding-xl;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a, .label {
  color: $text-color;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.5;

  &:hover, &.active {
    color: $primary-color;
  }

  &.active {
    opacity: 1;
  }
}

h1, h2 {
  font-family: "Sixtyfour", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "BLED" 0,
    "SCAN" 0;
  letter-spacing: 0.1rem; 
  line-height: 130%;
  text-transform: capitalize;

  @include md {
    line-height: 150%;
  }
}

h1 {
  font-size: 1.8rem;

  @include md {
    font-size: 2.3rem;
  }

  @include xl {
    font-size: 2.6rem;
  }
}

h2 {
  font-size: 1.3rem;

  @include md {
    font-size: 1.5rem;
  }

  @include xl {
    font-size: 1.6rem;
  }
}

h3 {
  line-height: 1rem;
}

.gradient {
  background: $gradient;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  line-height: 1.8rem;
  white-space: break-spaces;
}

button {
  background: transparent;
  border: none;
}

.EZDrawer .EZDrawer__container {
  background: transparent !important;
  z-index: 2 !important;
}
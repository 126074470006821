@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';

.videoWrapper {
    position: relative;
    width: calc(100% + $padding-sm * 2);
    height: 100lvh;
    align-content: center;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 64px;
    margin-left: -$padding-sm;

    @include md {
        width: calc(100% + $padding-md * 2);
        margin-left: -$padding-md;
    }

    @include xl {
        width: calc(100% + $padding-xl * 2);
        margin-left: -$padding-xl;
    }
}

.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 400ms;
}

.visible {
    opacity: 1;
}
  
.hidden {
    opacity: 0;
}

iframe {
    pointer-events: none;
}

$primary-color: #00c2ff;

$text-color: white;

$gradient: linear-gradient(
    45deg,
    $primary-color,
    #032774,
    #1982c4,
);

$padding-sm: 56px;
$padding-md: 80px;
$padding-xl: 150px;
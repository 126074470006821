@import '../../styles/variables.scss';

.cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(45deg) translate(-10px, 0);
    z-index: 999;
    border: 1px solid $primary-color;
    pointer-events: none;
}

.expand {
    .cursor::after {
        position: absolute;
        width: 10px;
        height: 10px;
        transform: rotate(45deg) translate(-10px, 0);
        z-index: 999;
        border: 1px solid $primary-color;
        pointer-events: none;
        animation: expand 500ms ease-out;
    }
    
}

@keyframes expand {
    0% {
        scale: 1;
    }
    100% {
        scale: 2;
    }
}
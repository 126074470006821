@import '../../styles/breakpoints.scss';
@import '../../styles/animations.scss';

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    margin: 0;
    padding: 16px;
    width: 100%;
    animation: slideDown 1s forwards, fadeIn 1s forwards;

    @include lg {
        top: 0;
        right: 0;
        padding: 16px;
    }

    @include xl {
        top: 0;
        right: 0;
        padding: 16px;
    }
}

.navlinks {
    display: none;

    @include sm {
        display: flex;
        gap: 1.4rem;
    }

    @include xl {
        display: flex;
        gap: 16px;
    }
}

.logo {
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.8;

    @include md {
        width: 32px;
        height: 32px;
    }
}

.actions {
    @include md {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
    }

    @include xl {
        display: flex;
        flex-direction: row-reverse;
        gap: 32px;
    }
}

.menuButton {
    @include md {
        display: none;
    }

    @include xl {
        display: none;
    }
}

.menuIcon {
    width: 24px;
    height: 24px;
    opacity: 0.6;
    color: white;
}

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 25dvw;
    height: 100lvh;
    background: rgba(0, 0, 0, 0.3);
    padding: 64px 16px 32px;
    transition: all 1s;
    transform: translateX(100%);

    body {
        overflow: hidden;
    }

    ul {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
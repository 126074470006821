@import '../../styles/variables.scss';
@import '../../styles/animations.scss';
@import '../../styles/breakpoints.scss';

.contact {
    width: 100%;
    height: 100lvh;
    padding: 80px 0 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    h1 {
        max-width: 75%;
    }

    a {
        font-family: "Sixtyfour", system-ui;
        font-size: 1.8rem;
        align-self: flex-end;
        text-transform: initial;
        opacity: 1;
    }
}

.transition {
    animation: fadeOut 1.2s forwards;
}

@import '../../styles/breakpoints.scss';
@import '../../styles/animations.scss';
@import '../../styles/variables.scss';

.project {
    position: relative;
    display: flex;
    flex-direction: column;
    mix-blend-mode: plus-lighter;
    height: 100%;
}

.info {
  margin-bottom: 64px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  opacity: 0.8;
}

.scrollIcon {
  position: absolute;
  top: calc(98lvh - 2rem);
  right: 50%;
  transform: translateX(50%);
  opacity: 0.8;
}

.backLink {
  position: fixed;
  display: inline-block;
  top: 72px;
  left: 16px;
  z-index: 2;
}

.fadeOut {
  animation: fadeOut 600ms forwards;
}

.linkedProject {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 0 64px;
  flex: 1;

  .direction {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 0;
    opacity: 0.5;
  }

  h2 {
    padding: 0;
  }
}

.previous {
  display: none;
  text-align: left;

  @include lg {
    display: flex;
  }
}

.next {
  align-items: flex-end;
  text-align: right;
}

.linkedProjectsWrapper {
  display: flex;
  justify-content: space-between;
}
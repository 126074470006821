@import '../../styles/breakpoints.scss';

.projects {
   // margin-top: 50lvh;
}

.stickyContainer {
    height: 400lvh;
    position: relative;
}

.someDomContent {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100lvh;
}

.projectName {
    text-transform: capitalize;
    text-align: center;
}

.projectInfo {
    align-self: flex-end;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@import '../../styles/variables.scss';
@import '../../styles/animations.scss';

.button {
    background: rgb(255 255 255 / 0%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    padding: 8px 32px;
    cursor: pointer;
    font-family: "Lekton", monospace;
    font-weight: 400;
    backdrop-filter: blur(6px);

    &:hover {
        background: $gradient;
        background-size: 400%;
        animation: glow 20s linear infinite;
    }
}